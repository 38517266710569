import React,  { useEffect, useState, useMemo } from "react";
import { getDoc, doc } from "firebase/firestore";
import {db} from '../firebase';
import { useUserAuth } from "../context/UserAuthContext";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Button } from '@mui/material';

let FullBettingHistory = [];
let bettingGot = false;
var AllParts = [];

async function fetchBets(user) {

  if(user && user !== null && user !== undefined && user.uid && user.uid !== null && user.uid !== undefined) {

     if(FullBettingHistory.length === 0) {
      const UserRecord = doc(db, "Users", user.uid);
      const UserRecordSnap = await getDoc(UserRecord);

      if(UserRecordSnap.exists()) {
        const UserRecordData = UserRecordSnap.data();
        const UserObjectArray = Object.entries(UserRecordData);

        let partCount = 0;
        if(UserObjectArray.length > 0) {
          UserObjectArray.forEach((value) => {
            if(value[0] === 'partCount') {
              partCount = value[1];
            }
          });
        }

        if(AllParts.length !== partCount && partCount !== undefined && partCount !== null && partCount !== 0) {
          for (let i = 1; i < partCount+1; i++) {
            let betdata = {};
            const docRef = doc(db, "Users_BettingHistory", user.uid+"_part_"+i);

            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {

              betdata = docSnap.data();
              console.log("betdata", betdata);
              AllParts.push(betdata);
              AllParts.forEach((betlist) => {
                for (const [key, value] of Object.entries(betlist)) {
                  var objectset = {
                    sport:        value['Sport'],
                    betid:        value['Bet ID'],
                    selection:    value['Selection'],
                    liability:    value['Liability (£)'],
                    oddsmatched:  value['Avg. odds matched'],
                    match:        value['Match'],
                    persistence:  value['Persistence'],
                    profitloss:   value['Profit/Loss (£)'],
                    betplaced:    value['Bet placed'],
                    market:       value['Market'],
                    bidtype:      value['Bid type'],
                    stake:        value['Stake (£)'],
                    oddsreq:      value['Odds req.']
                  };
                  FullBettingHistory[key] = objectset;
                  // console.log(objectset);
                  // if(!containsObject(objectset, FullBettingHistory)) {
                  //   FullBettingHistory.push(
                  //     objectset
                  //   );
                  // }
                }
              });
            } else {
              console.log("No such document!");
              return;
            }
          }
          bettingGot = true;
        }
        else {
          bettingGot = true;
        }
      }
      // BetData = FullBettingHistory;
      // if(BetData > 0 && FullBettingHistory.length === BetData.length) {
      //   bettingGot = true;
      // }
    }

  }
}

const ViewBets = () => {
  const { user } = useUserAuth();
  const [data, setData] = useState([]);

  const columns = useMemo(
    () => [
      {
        header: 'Sport',
        accessorKey: 'sport',
        sortable: true
      },
      {
        header: 'Bet ID',
        accessorKey: 'betid'
      },
      {
        header: 'Selection',
        accessorKey: 'selection'
      },
      {
        header: 'Liability (£)',
        accessorKey: 'liability'
      },
      {
        header: 'Avg. odds matched',
        accessorKey: 'oddsmatched'
      },
      {
        header: 'Match',
        accessorKey: 'match'
      },
      {
        header: 'Persistence',
        accessorKey: 'persistence'
      },
      {
        header: 'Profit/Loss (£)',
        accessorKey: 'profitloss'
      },
      {
        header: 'Bet placed',
        accessorKey: 'betplaced'
      },
      {
        header: 'Market',
        accessorKey: 'market'
      },
      {
        header: 'Bid type',
        accessorKey: 'bidtype'
      },
      {
        header: 'Stake (£)',
        accessorKey: 'stake'
      },
      {
        header: 'Odds req',
        accessorKey: 'oddsreq'
      }
    ],[]
  );

  useEffect(() => {
    const fetchData = async () => {

      try {
        if(!bettingGot) {
          await fetchBets(user);
          setData(FullBettingHistory);
        }
        // console.log("data",data);
      } catch (error) {
      }
    };
    fetchData();
    console.log("AllParts", AllParts);
  }, [user,
      data]);

  const table = useMaterialReactTable({
    columns,
    data,
    isMultiSortEvent: () => true, //now no need to hold `shift` key to multi-sort
    maxMultiSortColCount: 3, //prevent more than 3 columns from being sorted at once
    initialState: {
      sorting: [

      ],
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Button onClick={() => table.resetSorting(true)}>
        Clear All Sorting
      </Button>
    ),
  });

  return (
    <div>
      <h2>Bet History</h2>
      <MaterialReactTable table={table} />
    </div>
  )
}
export default ViewBets;
