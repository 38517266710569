// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import{getAuth} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAxgwxILlVSkW1Hn9selElg_Oz5g1SVbG4",
  authDomain: "coinsights-1988.firebaseapp.com",
  projectId: "coinsights-1988",
  storageBucket: "coinsights-1988.appspot.com",
  messagingSenderId: "1082585995697",
  appId: "1:1082585995697:web:2f88fe0e29bcaa5ee84590",
  measurementId: "G-PM63M1Y324"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Export Firebase Auth
export const auth = getAuth(app);
export default app;

// Export Firestore Database
export const db = getFirestore(app);
