import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useUserAuth } from "../context/UserAuthContext";

const Home = () => {
  const { logOut } = useUserAuth();
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };

  const CSVUpload = () =>{
    navigate("/UploadCSV");
  }

  const ViewBets = () =>{
    navigate("/ViewBets");
  }

  return (
    <Container style={{ width: "1000px" }}>
      <Row>
        <Col>
          <div className="p-4 box mt-3 text-center mb-1">
            Welcome
          </div>
        </Col>
      </Row>

      <Row>
        <Col style={{ textAlign: "center" }}>
          <Button style={{ width: "150px" }} variant="primary" onClick={handleLogout}>
            Log out
          </Button>
        </Col>
        <Col style={{ textAlign: "center" }}>
          <Button style={{ width: "150px" }} variant="primary" onClick={CSVUpload}>
            Upload Bets
          </Button>
        </Col>
        <Col style={{ textAlign: "center" }}>
          <Button style={{ width: "150px" }} variant="primary" onClick={ViewBets}>
            View Bets
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
