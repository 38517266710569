import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { setDoc, doc } from "firebase/firestore";
import {db} from '../firebase';
import { useUserAuth } from "../context/UserAuthContext";

const UploadCSV = () => {
  const { user } = useUserAuth();
  const [ file, setFile ] = useState();
  const [showForm, setShowForm] = useState("");
  const [showSuccess, setShowSuccess] = useState("d-none");
  const handleFormShow = () => setShowForm("d-none");
  const handleSuccessShow = () => setShowSuccess("");
  // const [array, setArray] = useState([]);

  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const csvFileToArray = string => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
    var count = 0;
    const array = csvRows.map(i => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object.bet_id = count;
        if(header === "Market") {
          let splitter = (values[index]).split(' / ');
          object.Sport = splitter[0];
          object.Match = splitter[1];
          object.Market = splitter[2];
        }
        else if(header === ""){
          // Do Nothing
        }
        else {
          if(header.includes("Profit/Loss")) {
            object["Profit/Loss (£)"] = values[index];
          }
          else if(header === "Bet ID") {
            object["Bet ID"] = Number(values[index]).toLocaleString('fullwide', {useGrouping:false});
          }
          else {
            object[header] = values[index];
          }
        }
        return object;

      }, {});
      count++;

      return obj;
    });

    var arraySet = [];
    var arrayCount = 0;
    var partnumber = 1;

    var overallCount = 0;
    var totalCount = array.length;

    console.log("partnumber", partnumber);
    console.log("arrayCount", arrayCount);
    console.log("overallCount", overallCount);
    console.log("totalCount", totalCount);

    array.forEach((element, key) => {
      arraySet.push(element);
      arrayCount++;
      overallCount++;
      console.log("partnumber", partnumber);
      console.log("arrayCount", arrayCount);
      console.log("overallCount", overallCount);
      console.log("totalCount", totalCount);

      if( arrayCount >= 499 || totalCount === overallCount) {
        var objectbets = convertArrayToObject(arraySet, 'bet_id');
        // delete objectbets[0];
        setDoc(doc(db, "Users_BettingHistory", user.uid+"_part_"+partnumber), objectbets);

        const UserRecord = doc(db, 'Users', user.uid);
        setDoc(UserRecord, { partCount: partnumber }, { merge: true });

        console.log("partnumber", partnumber);
        console.log("arrayCount", arrayCount);
        console.log("overallCount", overallCount);
        console.log("totalCount", totalCount);
        console.log("SENT DATA");

        partnumber++;
        arrayCount = 0;
        objectbets = [];
        arraySet = [];
      }

    });


    handleFormShow();
    handleSuccessShow();
    // setArray(array);
  };

  const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      };
    }, initialValue);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(file);
    }
  };

  return (
    <>
      <div  style={{textAlign: "center" }}>
        <h1>Import Betting History </h1>
        <Form className={showForm}>
          <Form.Group className="mb-3">
            {/* <Form.Label></Form.Label> */}
            <Form.Control
              type={"file"}
              id={"csvFileInput"}
              accept={".csv"}
              onChange={handleOnChange} />
          </Form.Group>

          <Button
            onClick={(e) => {
              handleOnSubmit(e);
            }}
          >
            Import CSV
          </Button>
        </Form>

        <p id="CSVSuccess" className={showSuccess}>CSV Successfully Uploaded</p>
      </div>
    </>
  );
};

export default UploadCSV;
